/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import axiosApi from "../axiosApi";
import Attachment from "../components/Attachment";
import Button from "../components/Button";
import Container from "../components/Container";
import LoadingModal from "../components/modals/LoadingModal";
import SelectionModal from "../components/modals/SelectionModal";
import Spinner from "../components/Spinner";
import TextInput from "../components/TextInput";
import { AttachmentData, EmailTemplate, ModelRef, ReservationDetailData } from "../types";
import useAlert from "../useAlert";

const generateDefaultSubject = (data: ReservationDetailData): string => {
    let subject = "";
    if (data.clients && data.clients.length > 0) {
        subject += data.clients[0].full_name;
        subject += " - ";
    }
    subject += data.title;
    return subject;
};

interface SendTabProps {
    reservationData: ReservationDetailData;
    updateStatusInHeader: () => void;
}

const SendTab: React.FC<SendTabProps> = ({ reservationData, updateStatusInHeader }) => {
    const [subject, setSubject] = useState(generateDefaultSubject(reservationData));
    const [message, setMessage] = useState("");
    const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[] | null>(null);
    const [attachments, setAttachments] = useState<AttachmentData[]>([]);
    const [isSelectAttachmentModalVisible, setIsSelectAttachmentModalVisible] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const { addAlert } = useAlert();

    const fetchEmailTemplates = () => {
        axiosApi.get("/email_templates/").then((response) => {
            setEmailTemplates(response.data);
        });
    };

    const handleSend = async () => {
        setIsSending(true);

        axiosApi
            .post(`/reservations/${reservationData.id}/send/`, { subject, message, attachments })
            .then(() => {
                setIsSending(false);
                addAlert("Se ha enviado la reserva.", "success");
                updateStatusInHeader();
            })
            .catch(() => {
                addAlert("Ha habido un error enviando la reserva.", "error");
                setIsSending(false);
            });
    };

    useEffect(() => {
        if (!emailTemplates) {
            fetchEmailTemplates();
        }
    });

    const loadingScreen = (
        <div className="flex items-center justify-center h-full w-full">
            <Spinner />
        </div>
    );

    if (!emailTemplates) {
        return loadingScreen;
    }

    return (
        <div className="pt-10">
            <h1 className="flex space-x-10 items-center font-black text-2xl pb-10">Enviar presupuesto</h1>

            <div className="flex h-full w-full">
                <Container>
                    <div className="flex flex-col pt-2 space-y-6">
                        <TextInput
                            id="client"
                            label="Cliente"
                            defaultValue={reservationData.clients?.at(0)?.full_name}
                            disabled
                        />
                        <TextInput
                            id="subject"
                            label="Asunto"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                        />
                        {/* <Many2one
                            id="email_template"
                            label="Plantilla"
                            data={emailTemplates.map((et) => ({ id: et.id, name: et.name } as ModelRef)) || []}
                            onClick={(selected: ModelRef) => {
                                // Set the text of the selected template
                                const emailTemplate = emailTemplates.find((et) => et.id === selected.id);
                                if (emailTemplate) {
                                    setMessage(emailTemplate.message);
                                }
                            }}
                        /> */}
                        <TextInput
                            id="message"
                            label="Mensaje"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            lines={6}
                        />

                        <div className="flex justify-between">
                            <div className="shadow">
                                <Button
                                    label="Adjuntar"
                                    title="Adjuntar documentación al presupuesto"
                                    type="btn_transparent"
                                    icon="attach_file"
                                    onClick={() => setIsSelectAttachmentModalVisible(true)}
                                />
                            </div>
                            <div className="shadow">
                                <Button
                                    label="Enviar"
                                    title="Enviar presupuesto"
                                    icon="send"
                                    onClick={async () => {
                                        handleSend();
                                    }}
                                    type="btn_yellow"
                                />
                            </div>
                        </div>

                        {attachments.length > 0 && (
                            <div className="flex flex-wrap">
                                {attachments.map((attachment) => (
                                    <div key={attachment.id} className="p-2">
                                        <Attachment
                                            name={attachment.name}
                                            onDelete={() =>
                                                setAttachments(attachments.filter((at) => at.id !== attachment.id))
                                            }
                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </Container>
            </div>

            {/* Modals */}
            <SelectionModal
                title="Documentación de la reserva"
                description="Selecciona qué documentos adjuntar de la lista de documentación asociada a esta resera."
                header="Fichero"
                visible={isSelectAttachmentModalVisible}
                visibleHandler={setIsSelectAttachmentModalVisible}
                values={reservationData.attachments.map((attachment) => ({ id: attachment.id, name: attachment.name }))}
                multiselection
                onAccept={(selected: ModelRef[]) => {
                    const selectedIds = selected.map((at) => at.id).filter((id) => id >= 0);
                    if (!selectedIds) return;
                    const attachmentIds = attachments.map((at) => at.id);
                    const newAttachments = reservationData.attachments.filter(
                        (attachment) => selectedIds.includes(attachment.id) && !attachmentIds.includes(attachment.id)
                    );
                    setAttachments(newAttachments);
                }}
            />

            <LoadingModal visible={isSending} text="Enviando el presupuesto..." />
        </div>
    );
};

export default SendTab;
