/* eslint-disable react/no-array-index-key */
import React from "react";
import { Choice, DayBlockData, ModelRef, ReservationDetailData } from "../types";
import { formatDate, getChoice, getInitials, getItineraryTitles } from "../utils";
import Avatar from "./Avatar";
import Many2one from "./Many2one";
import SidePanel from "./SidePanel";
import TagGroup from "./TagGroup";

export interface ReservationPanelProps {
    isSidePanelOpened: boolean;
    reservationData: ReservationDetailData;
    itinerary: DayBlockData[];
    travelTypes: Choice[];
    selectedVersion?: number;
    setSelectedVersion?: (version: number) => void;
    versions?: ModelRef[];
    className?: string;
}

/**
 * Side panel with some info from a reservation.
 */
const ReservationPanel: React.FC<ReservationPanelProps> = ({
    isSidePanelOpened,
    reservationData,
    itinerary,
    travelTypes,
    selectedVersion,
    setSelectedVersion,
    className,
    versions,
}) => {
    const itineraryTitles = getItineraryTitles(itinerary, reservationData.selected_version);
    const client = reservationData.clients?.at(0);

    const handleChangeItineraryVersion = async (selected: ModelRef) => {
        setSelectedVersion && setSelectedVersion(selected.id);
    };

    return (
        <div className={className}>
            <SidePanel isSidePanelOpened={isSidePanelOpened}>
                <div className="flex flex-col">
                    {client !== undefined && (
                        <div className="flex items-center space-x-5 mt-2">
                            <Avatar
                                image={client.profile_image?.image}
                                altText={getInitials(client.full_name)}
                                size="lg"
                            />
                            <h1 className="font-black text-2xl">{reservationData.clients?.at(0)?.full_name}</h1>
                        </div>
                    )}
                    <div className="flex flex-col mt-3">
                        <h1 className="font-black text-2xl">{reservationData.title}</h1>
                        <div className="flex items-center space-x-5">
                            <p>{formatDate(new Date(reservationData.travel_start_date), false, false)}</p>
                            <p>{reservationData.amount_travelers} viajeros</p>
                        </div>
                    </div>
                    <div className="mt-5">
                        <TagGroup title="Destinos" values={reservationData.countries} type="yellow" inline />
                        {reservationData.travel_type && (
                            <TagGroup
                                title="Tipo de viaje"
                                values={[getChoice(reservationData.travel_type, travelTypes)?.display || ""]}
                                type="yellow"
                                inline
                            />
                        )}
                    </div>
                    {versions && (
                        <div className="mt-7">
                            <Many2one
                                id="itinerary_version"
                                label="Versiones"
                                onClick={(selected: ModelRef) => {
                                    handleChangeItineraryVersion(selected);
                                }}
                                defaultValue={
                                    versions ? versions.find((version) => version.id === selectedVersion) : undefined
                                }
                                data={versions}
                            />
                        </div>
                    )}

                    {itineraryTitles.length > 0 && (
                        <div className="flex flex-col mt-7">
                            <p className="font-semibold text-grey text-sm pb-2">Itinerario</p>
                            {itineraryTitles.map((title, idx) => (
                                <p key={idx}>
                                    Día {idx + 1}: {title}
                                </p>
                            ))}
                        </div>
                    )}
                </div>
            </SidePanel>
        </div>
    );
};

export default ReservationPanel;
